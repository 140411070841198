import React, { ChangeEvent } from 'react';
import { Checkbox, Flex, Paragraph } from '@contentful/f36-components';

interface Props {
  newReferenceFields: object[]; // Stores newly found reference fields after entry selection
  handleFieldChange: Function;
  updateSelected: Function;
  selectedReferenceFields: string[];
}

export default class NewLocalisedReferences extends React.Component<Props> {
  render() {
    return (
      <Flex flexDirection="column" className="localised-references-container new-fields">
        <Paragraph>
          We found more content types related to your selections. Please check the boxes to include
          them.
        </Paragraph>
        <Flex
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
          }}
        >
          {this.props.newReferenceFields.map((field: any) => {
            return (
              <span key={field.id} style={{ margin: '0.35rem 0' }}>
                <Checkbox
                  value={field.id}
                  isChecked={this.props.selectedReferenceFields.includes(field.id)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    this.props.updateSelected(e.target.value);
                  }}
                  id={field.id}
                >
                  {field.name}
                </Checkbox>
                &nbsp;
              </span>
            );
          })}
        </Flex>
      </Flex>
    );
  }
}
