import {
  LOCALIZATION_METHOD,
  EMBEDDED_REFERENCE,
  SHOW_LINKED_REFERENCE,
  SELECTED_PROGRAM,
  SOURCE_CONTENT,
} from '../config/translationModel';
import { EditorExtensionSDK } from '../extensions-sdk';
import { migrationMap } from '../constants/migrationConstants';
import { ContentTypeAPI } from 'contentful-ui-extensions-sdk';
import { PROGRAM_SELECTION } from '../config/translatorModel';

export default class MigrationHelper {
  static isMigrationNeeded(sdk: EditorExtensionSDK): string {
    const { contentType } = sdk;
    if (!contentType) {
      return '';
    }

    const isTranslatorEditPage = this.isTranslatorContentType(sdk);
    if (isTranslatorEditPage) {
      return this.checkTranslators(contentType);
    } else {
      return this.checkTranslationProjects(contentType);
    }
  }

  static async runMigration(
    sdk: any,
    migration: string,
    updateLoadingText: Function,
  ): Promise<void> {
    if (migration) {
      // Get the class that needs to run
      const migrationClass = migrationMap[migration];
      await new migrationClass(sdk, updateLoadingText).runMigration();
    }
  }

  static checkTranslationProjects(contentType: ContentTypeAPI): string {
    if (!contentType.fields.find((f: any) => f.id === LOCALIZATION_METHOD)) {
      return '1.1'; // Version 1.1 upgrade needed
    } else if (
      contentType.fields.find((f: any) => f.id === SOURCE_CONTENT && f?.items?.type === 'Link')
    ) {
      return '1.3'; // Version 1.3 upgrade needed
    } else if (!contentType.fields.find((f: any) => f.id === EMBEDDED_REFERENCE)) {
      return '1.7.0'; // Version 1.7.0 upgrade needed
    } else if (!contentType.fields.find((f: any) => f.id === SHOW_LINKED_REFERENCE)) {
      return '1.7.1'; // Version 1.7.1 upgrade needed
    } else if (!contentType.fields.find((f: any) => f.id === SELECTED_PROGRAM)) {
      return '1.7.3'; // Version 1.7.3 upgrade needed
    } else {
      return '';
    }
  }

  static checkTranslators(contentType: ContentTypeAPI): string {
    switch (true) {
      case !contentType.fields.find((f: any) => f.id === PROGRAM_SELECTION):
        return '1.7.3';
      default:
        return '';
    }
  }

  static isTranslatorContentType(sdk: EditorExtensionSDK): boolean {
    return sdk.contentType.sys.id === sdk.parameters.installation.translatorModelId;
  }
}
