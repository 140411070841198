import React, { ChangeEvent } from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import {
  List,
  Flex,
  Spinner,
  Button,
  Checkbox,
  FormLabel,
  EntityList,
  EntityListItem,
  SkeletonContainer,
  SkeletonBodyText,
  Note,
  Tooltip,
  FormControl,
} from '@contentful/f36-components';
import { PlusIcon, PageIcon, HelpCircleIcon } from '@contentful/f36-icons';
import {
  getSelections,
  getContentTypes,
  getEntryId,
  TYPE_ENTRY,
  removeValueAndChildren,
} from '../../../utils/helpers';
import loGet from 'lodash.get';
import TrackChanges from '../parts/TrackChanges';
import { isMediaLinkType, MEDIA_LINK_TYPE } from '../../../utils/assetHelpers';
import { getSourceContentWithReferences } from '../../../utils/referenceEntryHelper';
import NewLocalisedReferences from '../parts/NestedLocalisedReferences';
import { isFieldReference } from '../../../utils/contentful';

interface Props {
  value: object[];
  trackChanges: boolean;
  handleFieldChange: Function;
  showNotice: Function;
  showTrackChanges: boolean;
  sdk: EditorExtensionSDK;
  showLinkedReference: boolean;
  selectedReferenceFields: string[];
  selectedEmbeddedReferences: string[];
}

interface State {
  selectedEntries: object[];
  contentTypes: object[];
  referenceFieldMap: any;
  newReferenceFields: object[];
  loading: boolean;
  checkedEntries: string[];
  totalEntries: number;
}

export default class SourceContentField extends React.Component<Props, State> {
  state: State = {
    selectedEntries: [],
    contentTypes: [],
    referenceFieldMap: null,
    newReferenceFields: [],
    loading: true,
    checkedEntries: [],
    totalEntries: -1,
  };
  timeout: any = null;

  constructor(props: Readonly<Props>) {
    super(props);
    //initially set the value once
    this.props.handleFieldChange('trackChanges', this.props.trackChanges);
  }

  async componentDidMount() {
    await this.handleMount();
    this.props.sdk.entry.fields.localizationMethod.onValueChanged(this.updateSourceContent);
    this.props.sdk.entry.fields.sourceContent.onValueChanged(this.updateSourceContent);
    // if source content is made empty, remove checked entries
    this.props.sdk.entry.fields.sourceContent.onValueChanged((sourceContentValue: any) => {
      if (!sourceContentValue || sourceContentValue.length == 0) {
        this.setState({
          checkedEntries: [],
        });
      }
    });
    this.props.sdk.entry.fields.localizedReferences.onValueChanged(this.updateSourceContent);
    this.props.sdk.entry.fields.selectedReferenceFields.onValueChanged(this.updateSourceContent);
    this.props.sdk.entry.fields.embeddedReference.onValueChanged(this.updateSourceContent);
    this.props.sdk.entry.fields.selectedEmbeddedReferences.onValueChanged(this.updateSourceContent);
  }

  handleMount = async () => {
    const contentTypes = await getContentTypes(this.props.sdk);
    this.setState({
      contentTypes: contentTypes,
    });
    this.updateSourceContent();
  };

  removeEntries = async () => {
    let sourceContent = this.props.sdk.entry.fields.sourceContent.getValue();
    const selectedEntries = sourceContent.filter((filterSelectEntry: { sys: { id: any } }) => {
      return !this.state.checkedEntries.includes(filterSelectEntry.sys.id);
    });
    this.setState({
      checkedEntries: [],
    });
    await this.updateSelectedEntries(selectedEntries);
  };

  updateSelectedEntries = async (selectedEntries: any) => {
    this.props.handleFieldChange(
      'sourceContent',
      selectedEntries.map((selectedEntry: any) => {
        return {
          sys: {
            id: selectedEntry.sys.id,
            linkType: 'Entry',
            type: 'Link',
          },
        };
      }),
    );
  };

  openEntrySelector = async () => {
    const value = loGet(this, 'props.sdk.entry.fields.contentType', {
      getValue: () => null,
    }) as { getValue: () => any }; // Assuming this is the expected type
    const contentType = value.getValue();
    if (contentType) {
      this.props.sdk.dialogs
        .selectMultipleEntries({
          contentTypes: [contentType],
        })
        .then((selectedEntries: any) => {
          if (selectedEntries && selectedEntries.length) {
            // eslint-disable-next-line react/no-access-state-in-setstate
            let sourceContent = this.props.sdk.entry.fields.sourceContent.getValue() || [];
            for (const selectedEntry of selectedEntries) {
              const existingEntry = sourceContent.find((stateEntry: { sys: { id: any } }) => {
                return stateEntry.sys.id == selectedEntry.sys.id;
              });
              if (!existingEntry) {
                sourceContent.push(selectedEntry);
              }
            }
            this.updateSelectedEntries(sourceContent);
          }
        });
    } else {
      this.props.sdk.notifier.error('Select content type first');
    }
  };

  getContentType = (selectedEntry: any) => {
    const contentType: any =
      this.state.contentTypes.find((contentType: any) => {
        return contentType.sys.id === selectedEntry.contentType;
      }) || {};
    return contentType.name;
  };

  openEntryEditor = (selectedEntry: any) => {
    const entryId = selectedEntry.entryId;
    if (isMediaLinkType(selectedEntry.type)) {
      this.props.sdk.navigator.openAsset(entryId, { slideIn: true });
    } else {
      this.props.sdk.navigator.openEntry(entryId, { slideIn: true });
    }
  };

  updateSelected = async (value: string) => {
    let selectedReferenceFields = [...this.props.selectedReferenceFields]; // Make a copy to avoid mutating props directly
    if (selectedReferenceFields.includes(value)) {
      let linkedContentTypes = await this.props.sdk.entry.fields['linkedContentTypes'].getValue();
      await removeValueAndChildren(value, selectedReferenceFields, linkedContentTypes);
    } else {
      selectedReferenceFields.push(value);
    }
    this.props.handleFieldChange('selectedReferenceFields', selectedReferenceFields);
  };

  updateSourceContent = () => {
    this.setState({
      loading: true,
    });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      getSourceContentWithReferences(
        this.props.sdk,
        this.props.showLinkedReference,
        this.props.selectedReferenceFields,
        this.props.selectedEmbeddedReferences,
      )
        .then(async (response: any) => {
          clearTimeout(this.timeout);
          await this.props.handleFieldChange('linkedContentTypes', response.linkedContentTypes);

          this.setState({
            selectedEntries: response.sourceEntries,
            referenceFieldMap: response.referenceFieldMap,
            newReferenceFields: response.localisedReferences,
            loading: false,
            totalEntries: -1,
          });
        })
        .catch((err) => {
          if (this.props.sdk.entry.fields.sourceContent.getValue().length > 0) {
            this.props.handleFieldChange('sourceContent', []);
          }
          this.setState({
            selectedEntries: [],
            referenceFieldMap: [],
            newReferenceFields: [],
            loading: false,
            totalEntries: -1,
          });
          console.log(err);
          this.props.showNotice(err.data);
        });
    }, 10);
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.showLinkedReference !== this.props.showLinkedReference) {
      this.updateSourceContent();
    }
  }

  updateCheckedEntries = (value: string, all = false) => {
    this.setState((state) => {
      const entries = state.selectedEntries.filter((entry: any) => {
        return state.referenceFieldMap[getEntryId(entry)] === undefined;
      });
      return {
        checkedEntries: getSelections(
          state.checkedEntries,
          entries,
          value,
          all,
          (e: any) => e.entryId,
        ),
      };
    });
  };

  selectAllEntries = async () => {
    this.setState({ loading: true });
    let limit = 100; // Starts giving response size too large after this
    let skip = 0;
    let selectedEntries: any[] = [];

    while (skip < this.state.totalEntries) {
      let resources = await this.getEntries(limit, skip);
      selectedEntries.push(...resources.items);
      skip += resources.items.length;
    }

    let sourceContent = this.props.sdk.entry.fields.sourceContent.getValue() || [];
    for (const selectedEntry of selectedEntries) {
      const existingEntry = sourceContent.find((stateEntry: { sys: { id: any } }) => {
        return stateEntry.sys.id == selectedEntry.sys.id;
      });
      if (!existingEntry) {
        sourceContent.push(selectedEntry);
      }
    }
    this.updateSelectedEntries(sourceContent);
  };

  setEntriesCount = async (contentTypeValue: string) => {
    let entryCounts = contentTypeValue ? (await this.getEntries(1, 0)).total : -1;
    if (this.state.totalEntries != entryCounts) {
      this.setState({ totalEntries: entryCounts });
    }
  };

  getEntries = async (limit: number, skip: number) => {
    return await this.props.sdk.cma.entry.getMany({
      query: {
        content_type: this.props.sdk.entry.fields['contentType'].getValue(),
        limit: limit,
        skip: skip,
      },
    });
  };

  render() {
    const { referenceFieldMap, selectedEntries, checkedEntries } = this.state;
    let parentEntries = selectedEntries
      .filter((e: any) => {
        let entryIdWithType = getEntryId(e);
        return referenceFieldMap[entryIdWithType] === undefined;
      })
      .map((e: any) => e.entryId);
    let hasContentTypeValue = this.props.sdk.entry.fields['contentType'].getValue();
    if (hasContentTypeValue !== undefined) {
      this.setEntriesCount(hasContentTypeValue);
    }

    return (
      <FormControl isRequired>
        <Flex flexDirection="row">
          <FormLabel htmlFor="sourceContent">
            {'Source content'} -{' '}
            {this.state.loading ? 'Loading..' : selectedEntries.length + ' selected'}
          </FormLabel>{' '}
          <Tooltip
            content={
              'Selected entry count may vary from what is shown in select all button. As we ignore the entries that does not have any content for translations.'
            }
          >
            <HelpCircleIcon
              className={'tooltip-icon'}
              variant={'muted'}
              style={{ paddingLeft: '5px' }}
            />
          </Tooltip>
        </Flex>
        {this.props.showTrackChanges && (
          <TrackChanges
            trackChanges={this.props.trackChanges}
            handleFieldChange={this.props.handleFieldChange}
          />
        )}
        {this.state.newReferenceFields && this.state.newReferenceFields.length > 0 && (
          <NewLocalisedReferences
            newReferenceFields={this.state.newReferenceFields}
            handleFieldChange={this.props.handleFieldChange}
            updateSelected={this.updateSelected}
            selectedReferenceFields={this.props.selectedReferenceFields}
          />
        )}
        {this.state.loading && (
          <>
            <Flex marginBottom="spacingL">
              <Note>
                Please be patient your data is being prepared. This may take some time depending on
                number of entries selected.
              </Note>
            </Flex>
            <SkeletonContainer>
              <SkeletonBodyText numberOfLines={4} />
            </SkeletonContainer>
          </>
        )}
        {!this.state.loading && selectedEntries.length > 0 && (
          <>
            {parentEntries.length > 0 && (
              <div className={'source-content-button-container'}>
                <div style={{ paddingLeft: '10px' }}>
                  <Checkbox
                    isChecked={checkedEntries.length == parentEntries.length}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      return this.updateCheckedEntries(evt.target.value, true);
                    }}
                    id={'select-all-checkbox'}
                  >
                    Select all
                  </Checkbox>
                </div>
                {checkedEntries.length > 0 && (
                  <Button
                    variant={'negative'}
                    size={'small'}
                    isDisabled={this.state.checkedEntries.length < 1}
                    onClick={async () => {
                      const response = await this.props.sdk.dialogs.openConfirm({
                        title: 'Remove selected entries?',
                        message: 'Are you sure you want to remove selected entries?',
                        intent: 'positive',
                        confirmLabel: 'Yes!',
                        cancelLabel: 'No',
                      });
                      if (response) {
                        this.removeEntries();
                      }
                    }}
                  >
                    Remove
                  </Button>
                )}
              </div>
            )}
            <List className="listOnScroll">
              <EntityList className={'source-content-list'}>
                {selectedEntries.map((selectedEntry: any) => {
                  return (
                    <div className={'checkbox-entity-list'} key={selectedEntry.entryId + 'parent'}>
                      {!selectedEntry.isEmbeddedEntry &&
                        parentEntries.includes(selectedEntry.entryId) && (
                          <Checkbox
                            key={selectedEntry.entryId + 'check'}
                            value={selectedEntry.entryId}
                            isChecked={checkedEntries.includes(selectedEntry.entryId)}
                            onChange={() => {
                              this.updateCheckedEntries(selectedEntry.entryId);
                            }}
                          />
                        )}
                      <EntityListItem
                        className={'source-content-item'}
                        // @ts-ignore
                        key={selectedEntry.entryId}
                        title={this.getContentType(selectedEntry)}
                        description={selectedEntry.entryName || 'Entry'}
                        contentType={(() => {
                          let text = '';
                          let type = isMediaLinkType(selectedEntry.type)
                            ? MEDIA_LINK_TYPE
                            : TYPE_ENTRY;
                          if (selectedEntry.isEmbeddedEntry) {
                            text = type + ' part of a richtext embedded field';
                          } else if (referenceFieldMap[getEntryId(selectedEntry)] !== undefined) {
                            text = type + ' part of a reference field';
                          }

                          return text;
                        })()}
                        onClick={this.openEntryEditor.bind(this, selectedEntry)}
                        status={selectedEntry.entryStatus}
                        withThumbnail={false}
                      />
                    </div>
                  );
                })}
              </EntityList>
            </List>
          </>
        )}
        <Flex flexDirection="row" marginTop="spacingM">
          <Button
            type="button"
            startIcon={<PlusIcon />}
            variant={'secondary'}
            onClick={this.openEntrySelector}
          >
            Add source entries
          </Button>
          <Flex marginLeft="spacingM">
            <Button
              isDisabled={!hasContentTypeValue || parentEntries.length > 0}
              startIcon={<PageIcon />}
              variant={'secondary'}
              onClick={this.selectAllEntries}
              size="medium"
            >
              {this.state.loading ? (
                <Spinner />
              ) : this.state.totalEntries >= 0 ? (
                `Add all ${this.state.totalEntries} entries`
              ) : (
                'No entries'
              )}
            </Button>
          </Flex>
        </Flex>
      </FormControl>
    );
  }
}
