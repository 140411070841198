import upgrade1_1 from '../migration/upgrade1_1';
import upgrade1_3 from '../migration/upgrade1_3';
import upgrade1_7_0 from '../migration/upgrade1_7_0';
import upgrade1_7_1 from '../migration/upgrade1_7_1';
import upgrade1_7_3 from '../migration/upgrade1_7_3';

export const migrationMap: {
  [key: string]: typeof upgrade1_1;
} = {
  '1.1': upgrade1_1,
  '1.3': upgrade1_3,
  '1.7.0': upgrade1_7_0,
  '1.7.1': upgrade1_7_1,
  '1.7.3': upgrade1_7_3,
};
